import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
import UseCasesSection from "./../components/UseCasesSection";
import VideoSection from "./../components/VideoSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="primary"
        size="large"
        //bgImage="https://source.unsplash.com/ugnrXk1129g/1600x800"
        bgImage="https://raw.githubusercontent.com/shyamvalsan/HugoBlog/5f61ebb5a196afb4cd4c24667676179be2de5e1c/static/img/banner.jpg"
        bgImageOpacity={1}
        title="Synthetic data. Real results."
        subtitle="Try Doppledata today."
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />
      <VideoSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        //title="Watch Doppledata in action"
        subtitle=""
        embedUrl="https://www.youtube.com/embed/HzUgfyldZIs"
      />
      <UseCasesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Use Cases"
        subtitle="Empower your use-case with synthetic data"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />
    </>
  );
}

export default IndexPage;
