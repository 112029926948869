import { useRef, useEffect, useState } from "react";
import auth0 from "./auth0";
import Paper from "@mui/material/Paper";
import { Button, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
const URL_END_POINT = "https://api.doppledata.com/upload/";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiFormControl-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
  },
});

// Make an API request to `/api/{path}`
export function apiRequest(path, method = "GET", data) {
  const accessToken = auth0.extended.getAccessToken();

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          auth0.extended.logout();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

export function apiRequestExternalPost(file, userData) {
  let formData = new FormData();
  formData.append("uploaded_file", file);
  formData.append("user_data", userData);

  let promise = axios.post(
    URL_END_POINT,
    formData,
    { timeout: 300000 },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return promise;
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

export function GenerateCSVFromTable(tableType) {
  let csv_data = [];

  if (tableType === "csv-upload") {
    var rows = document.getElementsByTagName("tr");
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell of
        // a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }
    // combine each row data with new line character
    csv_data = csv_data.join("\n");
  } else {
    // Get each row data
    let rows = document.getElementsByTagName("tr");
    let actual = [];
    for (let i = 0; i < rows.length; i++) {
      if (i === 0) {
        let headers = rows[i].querySelectorAll("th input");

        actual = Array.from(headers).filter((h) => h.value !== "");
        if (actual.length === 0) {
          alert("please add headers");
          return 0;
        }
      }

      // Get each column data
      let cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      let csvrow = [];
      for (let j = 0; j < actual.length; j++) {
        if (cols[j].getElementsByTagName("input")) {
          // Get the text data of each cell of
          // a row and push it to csvrow

          if (typeof cols[j].getElementsByTagName("input")[0] == "undefined") {
            continue;
          }
          csvrow.push(cols[j].getElementsByTagName("input")[0].value);
        }
      }

      //Discard empty rows
      let rowToBeAdded = csvrow.filter((row) => row !== "");
      if (rowToBeAdded.length === 0) continue;

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }
    // combine each row data with new line character
    csv_data = csv_data.join("\n");
  }

  return csv_data;
}

export function AddDeleteTableRows() {
  const classes = useStyles();
  const [noColumns, setNoColumns] = useState(5);
  const [rowsData, setRowsData] = useState([
    { col1: "", col2: "", col3: "", col4: "", col5: "" },
    { col1: "", col2: "", col3: "", col4: "", col5: "" },
    { col1: "", col2: "", col3: "", col4: "", col5: "" },
    { col1: "", col2: "", col3: "", col4: "", col5: "" },
    { col1: "", col2: "", col3: "", col4: "", col5: "" },
  ]);

  const addTableRows = () => {
    let rowsInput = {};
    [...Array(parseInt(noColumns))].map(
      (_, i) => (rowsInput[`col${i + 1}`] = "")
    );

    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table
          sx={{ minWidth: 750, maxHeight: 400 }}
          size="medium"
          aria-label="table"
          stickyHeader
          className={classes.table}
        >
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              {Array(parseInt(noColumns))
                .fill(true)
                .map((_, i) => (
                  <Headers key={i} />
                ))}
              <th>
                <Button
                  variant="text"
                  onClick={() => setNoColumns(noColumns + 1)}
                >
                  +
                </Button>
              </th>
            </TableRow>
          </TableHead>
          <tbody>
            <TableRows
              rowsData={rowsData}
              deleteTableRows={deleteTableRows}
              handleChange={handleChange}
              columns={noColumns}
            />
          </tbody>
          <tbody></tbody>
        </Table>
        <br></br>
        <>+</>
        <Button
          variant="text"
          onClick={addTableRows}
          sx={{ textTransform: "none" }}
        >
          Add a row
        </Button>
      </TableContainer>
    </Paper>
  );
}

export function SaveData(data) {
  let CSVFile = new Blob([data], { type: "text/csv" });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement("a");

  // Download csv file
  temp_link.download = "my-data.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}

function Headers() {
  const [name, setName] = useState("");

  return (
    <th>
      <TextField
        id="outlined-name"
        label="Header"
        margin="dense"
        size="small"
        variant="filled"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
    </th>
  );
}

function TableRows({ rowsData, deleteTableRows, handleChange, columns }) {
  return rowsData.map((data, index) => {
    return (
      <tr key={index}>
        {[...Array(parseInt(columns))].map((_, i) => {
          return (
            <td key={i + 1}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={data[`col${i}`]}
                onChange={(evnt) => handleChange(index, evnt)}
                name={`col${i}`}
              />
            </td>
          );
        })}
        <td>
          <Button
            variant="text"
            color="error"
            onClick={() => deleteTableRows(index)}
          >
            -
          </Button>
        </td>
      </tr>
    );
  });
}

export function ValidateCSVFile(file) {
  let validation = {};
  // Check if the file is empty or not
  if (file.size === 0) {
    validation.error = true;
    validation.message = "CSV file is empty. Please select a valid file.";
    return validation;
  }

  // Read the file and check for any errors
  try {
    let reader = new FileReader();
    reader.readAsText(file);
  } catch (e) {
    validation.error = true;
    validation.message = "CSV file corrupt. Please select a valid file.";
    return validation;
  }

  if (file.type !== "text/csv") {
    // File is not a CSV
    validation.error = true;
    validation.message = "CSV file not provided. Please select a valid file.";
    return validation;
  }

  if (file.size > 5 * 1024 * 1024) {
    // File is larger than 5 MB
    validation.error = true;
    validation.message =
      "CSV file is too big. Please select a file less than 5 MB.";

    return validation;
  }

  //if file passes all checks, return false
  validation.error = false;
  validation.message = "";
  return validation;
}
