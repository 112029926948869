import React from "react";
import { useEffect } from "react";
import Meta from "./../components/Meta";
import DashboardSection from "../components/DashboardSectionUserInput";
import { requireAuth } from "./../util/auth";
import { useAuth } from "../util/auth";
const { updateUser } = require("../util/db");

function DashboardPage(props) {
  const auth = useAuth();

  useEffect(() => {
    auth.user.planIsActive
      ? updateUser(auth.user.id, { plan: "pro" })
      : updateUser(auth.user.id, { plan: "free" });
  }, []);

  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        subtitle=""
      />
    </>
  );
}

export default requireAuth(DashboardPage);
