import React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { Button, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Link from "@mui/material/Link";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getUser } from "../util/db";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import LinearProgress from "@mui/material/LinearProgress";

import {
  GenerateCSVFromTable,
  AddDeleteTableRows,
  SaveData,
  apiRequestExternalPost,
  ValidateCSVFile,
} from "../util/util";
import Papa from "papaparse";
import DashboardUserTable from "./DashboardUserTable";
import LoadingButton from "@mui/lab/LoadingButton";
import DasboardCsvUpload from "./DashboardCsvUpload";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    violet: createColor("#3f51b5"),
  },
});

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
    minHeight: "50px",
  },
  gridTable: {
    padding: theme.spacing(3),
    minHeight: "500px",
    minWidth: "1200px",
  },
  item: { display: "flex", flexDirection: "column" },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(indigo[500]),
  backgroundColor: indigo[500],
  "&:hover": {
    backgroundColor: indigo[700],
  },
  marginLeft: "5px",
}));

const Item = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [showUserTable, setShowUserTable] = useState(false);
  const [showGenerateDataTableButton, setShowGenerateDataTableButton] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [showCsvTable, setshowCsvTable] = useState(false);
  const [dataRequested, setDataRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseCsv, setResponseCsv] = useState("");
  const [noOfRows, setNoOfRows] = useState(10);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState(false);
  const [quotaError, setQuotaError] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState("");
  let [requestCount, setRequestCount] = useState(0);
  let [userQuota, setUserQuota] = useState(1000);

  let userData = {};

  useEffect(() => {
    if (!responseCsv) return;
    Papa.parse(responseCsv, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        if (results.errors.length > 0) {
          return;
        }
        setTableHeaderAndRows(results);
      },
    });
    setLoading(false);
  }, [responseCsv]);

  useEffect(() => {
    if (error) setApiError(true);
  }, [error]);

  useEffect(() => {
    if (selectedFile === undefined) {
      return;
    }

    if (!dataRequested) {
      return;
    }

    setLoading(true);

    userData["user_id"] = auth.user.id ? auth.user.id : "";
    userData["requested_row_count"] = noOfRows;

    apiRequestExternalPost(selectedFile, JSON.stringify(userData))
      .then((response) => {
        setResponseCsv(response.data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [selectedFile, dataRequested, requestCount]);

  useEffect(() => {
    setFileUploaded(false);
  }, [fileUploadError]);

  useEffect(async () => {
    const { quota } = await getUser(auth.user.id);
    setUserQuota(quota);
  }, []);

  const setMinMaxRows = async (e, min) => {
    let value = parseInt(e.target.value);
    if (Number.isNaN(value)) {
      setNoOfRows(10);
    } else {
      if (value > userQuota) value = userQuota;
      if (value < min) value = min;

      setNoOfRows(value);
    }
  };

  const routeChange = () => {
    window.location.reload(true);
  };

  const generateDataFromApi = async () => {
    if (!selectedFile) {
      alert("please choose a file");
      return;
    }
    setApiError(false);
    const { quota } = await getUser(auth.user.id);
    if (quota <= 0) {
      setQuotaError(true);
      return;
    }
    setDataRequested(true);
    setRequestCount(++requestCount);
  };

  const generateDataFromTable = () => {
    let csv = GenerateCSVFromTable();
    if (csv === 0) {
      return;
    }
    let csvFile = new Blob([csv], { type: "text/csv" });

    setSelectedFile(csvFile);
    setFileUploaded(true);
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        if (results.errors.length > 0) {
          return;
        }
        setTableHeaderAndRows(results);
      },
    });
  };

  const onDownload = () => {
    SaveData(responseCsv);
  };

  const csvUploadHandler = async (event) => {
    const file = event.target.files[0];
    let validate = ValidateCSVFile(file);
    if (validate.error) {
      setFileUploadError(validate.error);
      setFileUploadErrorMsg(validate.message);
      return;
    }

    setSelectedFile(file);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        if (results.errors.length > 0) {
          setFileUploadError(true);
          setFileUploadErrorMsg(`CSV Error: ${results.errors[0].message}`);
          return;
        }
        setTableHeaderAndRows(results);
      },
    });
    setFileUploaded(true);
  };

  const setTableHeaderAndRows = (csvData) => {
    let result = [];
    let headers = csvData.meta.fields;
    let th = [];

    if (!headers) return;

    headers.forEach((h) => {
      let objHeader = {};
      objHeader["id"] = h;
      objHeader["label"] = h;
      objHeader["minWidth"] = 170;
      th.push(objHeader);
    });

    setTableHeaders(th);

    for (let i = 0; i < csvData.data.length; i++) {
      let obj = {};

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = csvData.data[i][headers[j]];
      }

      result.push(obj);
    }
    setTableRows(JSON.stringify(result));
    setshowCsvTable(true);
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {!showUserTable && !fileUploaded && (
          <Grid container rowSpacing={1} spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="centre"
                pl={40}
                sx={{
                  color: "#3f51b5",
                  borderColor: "#3f51b5",
                  marginRight: "1rem",
                  fontWeight: "bold",
                  fontFamily: "Playfair Display",
                }}
              ></Typography>
              <StyledTypography
                textAlign="center"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#3f51b5",
                }}
              >
                Choose an option to generate synthetic data
              </StyledTypography>
            </Grid>
          </Grid>
        )}

        <Grid container={true} spacing={4}>
          {!showUserTable && !fileUploaded && !dataRequested && (
            <Grid item={true} xs={12} md={6}>
              <DasboardCsvUpload
                changeHandler={csvUploadHandler}
                dataRequested={dataRequested}
              />
              {fileUploadError ? (
                <Alert severity="error" spacing={2}>
                  {fileUploadErrorMsg}
                </Alert>
              ) : null}
            </Grid>
          )}
          {!showUserTable && !fileUploaded && (
            <Grid item={true} xs={12} md={6}>
              {!fileUploaded && (
                <Paper>
                  <Box
                    m={10}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={10}
                  >
                    <Grid container rowSpacing={1} spacing={2}>
                      <Grid item xs={12}>
                        <StyledTypography sx={{ fontSize: "1rem" }}>
                          No data to upload?
                          <br />
                          No problem. Just define it.
                        </StyledTypography>
                      </Grid>

                      <Grid item xs={12}>
                        {!showUserTable && (
                          <Button
                            onClick={() => {
                              setShowUserTable(true);
                              setShowGenerateDataTableButton(true);
                            }}
                            startIcon={<AddIcon />}
                            variant="outlined"
                            sx={{ color: "#3f51b5", borderColor: "#3f51b5" }}
                            margin="dense"
                          >
                            Create Table
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              )}
            </Grid>
          )}
          {showUserTable && !fileUploaded && (
            <Paper>
              <Box
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                {showUserTable && !fileUploaded && (
                  <Box sx={{ margin: 10, padding: 5 }}>
                    <Button
                      onClick={routeChange}
                      variant="outlined"
                      sx={{
                        color: "#3f51b5",
                        borderColor: "#3f51b5",
                        marginBottom: 3,
                      }}
                      margin="dense"
                      startIcon={<ChevronLeftRoundedIcon />}
                    >
                      Back
                    </Button>
                    <AddDeleteTableRows />
                    <br></br>
                  </Box>
                )}
                {showGenerateDataTableButton && !fileUploaded && (
                  <Box
                    sx={{
                      p: 1,
                      minWidth: 300,
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        color="violet"
                        size="medium"
                        endIcon={<SendIcon />}
                        onClick={generateDataFromTable}
                      >
                        Submit
                      </Button>
                    </ThemeProvider>
                  </Box>
                )}
              </Box>
            </Paper>
          )}
          {fileUploaded && (
            <Grid item={true} xs={12} md={6} className={classes.gridTable}>
              <Box>
                <Button
                  onClick={routeChange}
                  variant="outlined"
                  sx={{
                    color: "#3f51b5",
                    borderColor: "#3f51b5",
                    marginBottom: 3,
                  }}
                  margin="dense"
                  startIcon={<ChevronLeftRoundedIcon />}
                >
                  Back
                </Button>
              </Box>
              <Box>
                {showCsvTable && (
                  <DashboardUserTable
                    headers={tableHeaders}
                    tableRows={tableRows}
                    loading={loading}
                  ></DashboardUserTable>
                )}
                <br></br>
                {dataRequested && !apiError ? (
                  <>
                    {loading ? <LinearProgress></LinearProgress> : null}

                    <br></br>
                    <LoadingButton
                      onClick={() => onDownload()}
                      variant="contained"
                      color="secondary"
                      loading={loading}
                      endIcon={<DownloadRoundedIcon />}
                    >
                      Download
                    </LoadingButton>
                  </>
                ) : (
                  <Box>
                    <TextField
                      tag="row-count"
                      size="small"
                      type="number"
                      label="Rows to generate"
                      value={noOfRows}
                      sx={{ margin: 3 }}
                      inputProps={{ min: 1, id: "rowcount" }}
                      onChange={(e) => setMinMaxRows(e, 1)}
                    ></TextField>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        color="violet"
                        size="medium"
                        endIcon={<SendIcon />}
                        onClick={generateDataFromApi}
                        sx={{ margin: 3 }}
                      >
                        Synthesize
                      </Button>
                    </ThemeProvider>

                    {apiError ? (
                      <Alert
                        severity="error"
                        sx={{ width: "100%" }}
                        spacing={2}
                      >
                        Something went wrong! Message: {error.message}
                      </Alert>
                    ) : null}

                    {quotaError ? (
                      <Alert
                        severity="error"
                        sx={{ width: "100%" }}
                        spacing={2}
                      >
                        Sorry, you have exceeded your daily free quota.
                      </Alert>
                    ) : null}
                  </Box>
                )}
              </Box>
            </Grid>
          )}

          <Box display="flex">
            {!showUserTable && !fileUploaded && (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <OndemandVideoIcon
                    fontSize="medium"
                    sx={{ fontSize: "1.1rem", mt: 4 }}
                  />
                  <Item
                    href="https://www.youtube.com/embed/HzUgfyldZIs"
                    target="_blank"
                    underline="hover"
                    sx={{ fontSize: "1.1rem" }}
                  >
                    Getting started (video)
                  </Item>
                </Grid>
                <Grid item xs={8}>
                  <MenuBookIcon fontSize="medium" sx={{ fontSize: "1.1rem" }} />
                  <Item
                    href="https://dopple-test.readthedocs.io/en/latest/"
                    target="_blank"
                    underline="hover"
                    sx={{ fontSize: "1.1rem" }}
                  >
                    Read the docs
                  </Item>
                </Grid>
                <Grid item xs={8}>
                  <CalendarMonthIcon
                    fontSize="medium"
                    sx={{ fontSize: "1.1rem" }}
                  />
                  <Item
                    href="https://calendly.com/doppledata/30min"
                    target="_blank"
                    underline="hover"
                    sx={{ fontSize: "1.1rem" }}
                  >
                    Schedule a call with us
                  </Item>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <StyledTypography
                  sx={{
                    fontSize: "1.1rem",
                    marginBottom: () => (auth.user.planIsActive ? 10 : 1),
                    marginTop: 2,
                    paddingLeft: () =>
                      !showUserTable && !fileUploaded ? 20 : 110,
                  }}
                >
                  Daily quota: <strong>{userQuota}</strong> rows left
                </StyledTypography>
              </Grid>
              {!auth.user.planIsActive && (
                <Grid item xs={12}>
                  <Item
                    href="/pricing"
                    target="_blank"
                    underline="hover"
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      color: "#3f51b5",
                      padding: 0,
                      pl: () => (!showUserTable && !fileUploaded ? 20 : 110),
                    }}
                  >
                    Upgrade to Pro!
                  </Item>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSection;
