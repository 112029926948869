import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What is synthetic data?",
      answer:
        "Synthetic data is data that is artificially created to closely resemble real-world data, without copying or memorizing any specific real-world data set. Doppledata generates synthetic data based on the user's direction or based on a dataset that the user provides as input.",
    },
    {
      question: "Why do I need synthetic data?",
      answer:
        "Synthetic data can be useful for a variety of tasks, such as creating realistic prototypes, training machine learning models, testing software, or protecting sensitive information in a dataset by replacing it with synthetic data.",
    },
    {
      question: "What kinds of data can be synthetically generated?",
      answer:
        "Theoretically almost any kind of data you can think of can be synthetically generated. By using the Doppledata free or pro tier, you can generate tabular data (we currently support input datasets in CSV format, or the user can create their source dataset from the app directly). Doppledata enterprise tier supports custom datasets (not limited to CSV).",
    },
    {
      question: "What if I have multiple tables which are related?",
      answer:
        "If you have different datasets which are related and the synthetic data that's generated should maintain those relationships, you will need to use Doppledata enterprise plan.",
    },
    {
      question: "What if my data is not in CSV or tabular format?",
      answer:
        "If your data is not in CSV or tabular format, we recommend reaching out to us for a demonstration of Doppledata enterprise mode. We customize the data generation to suit your dataset formats",
    },
    {
      question: "How is the synthetic data being generated?",
      answer:
        "There are many different methods for generating synthetic data, including statistical methods, generative models, and rule-based methods. The specific method used will depend on the type of data being synthesized and the desired characteristics of the synthetic data.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
