import * as React from "react";
import { Button, Box, Grid } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
  paperItems: {
    minHeight: "100px",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

export default function DasboardCsvUpload({ changeHandler, dataRequested }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperItems}>
      <Box
        m={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={10}
      >
        <Grid container rowSpacing={1} spacing={2}>
          <Grid item xs={12}>
            <StyledTypography sx={{ fontSize: "1rem" }}>
              Upload a CSV to generate similar data
            </StyledTypography>
          </Grid>

          <Grid item xs={12}>
            {!dataRequested && (
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{
                  color: "#3f51b5",
                  borderColor: "#3f51b5",
                  marginRight: "1rem",
                }}
              >
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={changeHandler}
                />
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
