import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
}));

function UseCasesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Design, Prototype and Demo",
      body: 'Generate data that meets your needs in seconds. Build relevant designs and prototypes that simply "make sense" for users.',
      image:
        "https://raw.githubusercontent.com/doppleadmin/uploads/main/demo.jpg",
    },
    {
      title: "Product development and testing",
      body: "Use the next best thing to real data while building and testing your products. If the the real data doesn't exist yet, try DoppleData.",
      image:
        "https://raw.githubusercontent.com/doppleadmin/uploads/main/dev.jpg",
    },
    {
      title: "Privacy protection and Data sharing",
      body: "Stay safe when sharing by using synthetic data that does the job instead of user data. Say goodbye to GDPR, CCPA and other abbreviated privacy worries.",
      image:
        "https://raw.githubusercontent.com/doppleadmin/uploads/main/privacy.jpg",
    },
    {
      title: "Train and test AI/ML solutions",
      body: "AI and ML models can't have enough of data, whether its for training or validation. High quality synthetic data improves model accuracy and helps reduce bias.",
      image:
        "https://raw.githubusercontent.com/doppleadmin/uploads/main/ml.jpg",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default UseCasesSection;
